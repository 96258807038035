@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-fade-in-left {
  animation: fadeInLeft 0.5s ease-out forwards;
}

.animate-fade-in-up {
  animation: fadeInUp 0.5s ease-out forwards;
}

.animate-fade-in-scale {
  animation: fadeInScale 0.5s ease-out forwards;
}

.animate-slide-in-right {
  animation: slideInRight 0.5s ease-out forwards;
}

.delay-200 {
  animation-delay: 200ms;
}

.delay-400 {
  animation-delay: 400ms;
}
@keyframes treeMove {
  0% {
    transform: translateY(0) translateX(0) rotate(0deg); /* Start straight */
  }
  25% {
    transform: translateY(-10px) translateX(5px) rotate(3deg); /* Slight upward and right slant with rotation */
  }
  50% {
    transform: translateY(-20px) translateX(10px) rotate(5deg); /* Fully upward slant to right with more rotation */
  }
  75% {
    transform: translateY(-10px) translateX(5px) rotate(3deg); /* Move back slightly downward with rotation */
  }
  100% {
    transform: translateY(0) translateX(0) rotate(0deg); /* Return to starting position */
  }
}


@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25);
  }
  100% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
}

.ripple,
.ripple:before,
.ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25);
  animation: ripple 3s infinite;
}

.ripple:before {
  animation-delay: 0.9s;
  content: "";
}

.ripple:after {
  animation-delay: 0.6s;
  content: "";
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-up {
  animation: fade-in-up 1.0s ease-out forwards;
}

@keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0.75;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

.group-hover\:animate-ripple:hover > span {
  animation: ripple 1s ease-out;
}

@keyframes faqMoveRotate {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-10px) rotate(2deg);
  }
  50% {
    transform: translateY(0) rotate(0deg);
  }
  75% {
    transform: translateY(10px) rotate(-2deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out forwards;
}

.fade-in-content {
  animation: fadeIn 1.5s ease-in-out 0.5s forwards;
}

.animate-swipe {
  animation: swipe 0.5s ease-in-out;
}

@keyframes swipe {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.glassmorphism {
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.glassmorphism-card {
  backdrop-filter: blur(12px);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.neon-text {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7), 0 0 20px rgba(0, 255, 0, 0.5);
}

.neon-glow {
  filter: drop-shadow(0 0 10px rgba(0, 255, 0, 0.7));
}


